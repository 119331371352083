const scrollBehavior = {
  behavior: 'smooth',
  block: 'end',
  inline: 'nearest',
};

export function scrollIntoViewIfNeeded(element) {
  if (checkInView(element)) return;

  element.scrollIntoView(scrollBehavior);
}

export const scrollToInvalid = () => {
  const firstInvalidElement = document.querySelector('.input--invalid');
  if (firstInvalidElement) firstInvalidElement.scrollIntoView(scrollBehavior);
};

function checkInView(element) {
  // This heavily relies on the element to check being nested inside main
  // and main being the parent in which scrolling happens)
  const container = document.querySelector('main');
  const containerBox = container.getBoundingClientRect();

  const elementBox = element.getBoundingClientRect();

  const inView = containerBox.bottom > elementBox.bottom;
  return inView;
}

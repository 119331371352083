export default function moderationMailTemplates() {
  return {
    selectedTemplate: null,

    handleTemplateSelected(evt) {
      const selectedTemplateId = evt.target.value;
      this.selectedTemplate = document.querySelector(`[data-template-id='${selectedTemplateId}']`);

      if (selectedTemplateId) {
        this.setEditorContent();
        this.setRecipients();
        this.setSubject();
      } else {
        this.clearEditorContent();
        this.clearRecipients();
        this.clearSubject();
      }
    },

    setEditorContent() {
      const content = this.selectedTemplate.innerHTML;
      this.$refs.editor && tinymce.get(this.$refs.editor.id).setContent(content);
    },

    clearEditorContent() {
      this.$refs.editor && tinymce.get(this.$refs.editor.id).setContent('');
    },

    setRecipients() {
      const recipients = this.selectedTemplate.dataset.recipients || '';
      this.$refs.recipients.value = recipients;
    },

    clearRecipients() {
      this.$refs.recipients.value = '';
    },

    setSubject() {
      const subject = this.selectedTemplate.dataset.subject;
      this.$refs.subject.value = subject;
    },

    clearSubject() {
      this.$refs.subject.value = '';
    },
  };
}

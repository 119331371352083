export default function settingsWizard() {
  return {
    openPage: 1,

    init() {
      this.$watch('open', () => { this.focusableEls = this.setFocusableElements() });
      this.$watch('openPage', () => { this.focusableEls = this.setFocusableElements() });
    },

    setFocusableElements() {
      const visibleModalElement = document.querySelector(`.settings-wizard__card[x-show="openPage === ${this.openPage}"]`);
      const selectors = 'a[href]:not([disabled]), button:not([disabled]), textarea:not([disabled]), input[type="text"]:not([disabled]), input[type="radio"]:not([disabled]), input[type="checkbox"]:not([disabled]), select:not([disabled])';
      const modalClose = document.querySelector('.modal__close');
      let focusableElements = visibleModalElement.querySelectorAll(selectors);

      focusableElements = Array.from(focusableElements);

      focusableElements.unshift(modalClose);

      return focusableElements;
    }
  }
}

import { translate } from '~/scripts/locales/translations';

const locale = document.querySelector('html').getAttribute('lang');

export default function projectPath({ permittedDomains, currentDomainId, currentPath, forbiddenUrls }) {
  return {
    permittedDomains: JSON.parse(permittedDomains),
    currentDomainId,
    currentPath,
    forbiddenUrls: JSON.parse(forbiddenUrls),
    validPath: true,
    newDomainId: null,
    newPath: null,

    init() {
      this.newDomainId = Object.keys(this.permittedDomains)[0];
      this.$watch('newPath', () => {
        this.isValidPath();
      });
      this.$watch('newDomainId', () => {
        this.isValidPath();
      });
      this.isValidPath();
      this.errorMessage();
    },

    isValidPath() {
      this.validPath = this.isValidUrl() && this.isCorrectFormat() && this.isCorrectLength();
    },

    isValidUrl() {
      const domain = this.newDomainId
        ? this.permittedDomains[this.newDomainId]
        : this.permittedDomains[this.currentDomainId];
      const path = this.newPath !== null ? this.newPath : this.currentPath;
      const url = [domain, path].join('/').replace(/\/$/, '');
      return !Object.values(this.forbiddenUrls).includes(url);
    },

    isCorrectFormat() {
      if (!this.newPath) return true;

      return /^[a-zA-Z0-9-_]*$/.test(this.newPath);
    },

    isCorrectLength() {
      if (!this.newPath) return true;

      return this.newPath.length >= 3 && this.newPath.length <= 50;
    },

    errorMessage() {
      if (!this.isValidUrl) {
        this.$refs.pathErrorMessage.innerHTML = translate('path_error_messages', 'already_taken', locale);
        this.$refs.pathInput.classList.remove('input--invalid');
      } else if (!this.isCorrectFormat()) {
        this.$refs.pathErrorMessage.innerHTML = translate('path_error_messages', 'wrong_format', locale);
        this.$refs.pathInput.classList.add('input--invalid');
      } else if (!this.isCorrectLength()) {
        this.$refs.pathErrorMessage.innerHTML = translate('path_error_messages', 'wrong_length', locale);
        this.$refs.pathInput.classList.add('input--invalid');
      } else {
        this.$refs.pathErrorMessage.innerHTML = '';
        this.$refs.pathInput.classList.remove('input--invalid');
      }
    },
  };
}

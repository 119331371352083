export default function sidebar() {
  return {
    expanded: false,
    openGroup: "",

    init() {
      const trigger = document.getElementById('sidebar-collapse-trigger');
      trigger.addEventListener('click', () => {
        this.expanded = !this.expanded;
      });

      const groups = this.$root.querySelectorAll('[x-bind="groupContent"]');
      
      groups.forEach((group) => {
        if (group.querySelector('.nav__item--active')) this.openGroup = group.id;
      })
    },
    toggleGroup(id) {
      this.openGroup = this.openGroup === id ? "" : id;
    },

    groupContent: {
      ['x-transition:enter']() {
        return 'transition ease-out duration-1000 overflow-y-hidden';
      },
      ['x-transition:enter-start']() {
        return 'h-0';
      },
      ['x-transition:enter-end']() {
        return 'h-full';
      },
      ['x-transition:leave']() {
        return 'transition ease-in duration-1000 overflow-y-hidden';
      },
      ['x-transition:leave-start']() {
        return 'h-full';
      },
      ['x-transition:leave-end']() {
        return 'h-0';
      },
    }
  }
}
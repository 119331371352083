import ImageBlobReduce from 'image-blob-reduce';

// utils
import fireSwal from '~/scripts/fire-swal';
import { translate } from '~/scripts/locales/translations';

const MAX_IMAGE_SIZE = 10485760; // 10 MB
const MAX_IMAGE_DIMENSION = 1600; // px

const reduce = new ImageBlobReduce();
const locale = document.querySelector('html').getAttribute('lang');

export default function fileUpload({ filename, fileExists }) {
  return {
    filename: filename || translate('img-resize', 'no-file-selected', locale),
    open: false,
    image_url: null,
    fileExists: fileExists == 'true',
    showPlaceholder: fileExists != 'true',

    async handleImageURL(url) {
      const preview = this.$refs.preview;
      const filename = String(this.image_url).split('/').pop();
      const fileExtension = filename.split('.').pop();
      const videoExtensions = ['mp4', 'mov', 'avi', 'wmf', 'flv', 'webm'];
      const errorElement = document.getElementById('file-input__error-message');
      const inputField = document.getElementById(url);

      if (this.image_url) {
        const re = new RegExp('^https*://', 'i');
        if (re.test(this.image_url)) {
          if (videoExtensions.includes(fileExtension)) {
            if (preview) {
              preview.src =
                '/assets/file_icons/video-27171972abad8c900fcd039956ad4816c8d6e4f15e3b9a4e023832356e9c40e2.png';
              preview.classList.remove('image-upload__preview');
            }
          } else {
            if (preview) {
              preview.src = this.image_url;
              if (!preview.classList.contains('image-upload__preview')) {
                preview.classList.add('image-upload__preview');
              }
            }
          }
          this.filename = filename;
          this.open = false;
          this.showPlaceholder = false;
          const input = this.$refs.input;
          input.value = null;
        } else {
          errorElement.innerHTML = translate('img-upload', 'error-message', locale);
          inputField.classList.add('border-red-600');
        }
      }
    },

    handleInput() {
      const preview = this.$refs.preview;
      const input = this.$refs.input;
      const file = input.files[0];

      if (file) {
        if (file.type.includes('image')) {
          fireSwal.loading({ title: translate('img-resize', 'analyzing', locale) });
          if (file.size > MAX_IMAGE_SIZE) {
            fireSwal.error({
              title: translate('img-resize', 'size-too-large__title', locale),
              text: translate('img-resize', 'size-too-large__text', locale),
            });
            input.value = null;
            return;
          } else if (file.name.includes('`')) {
            fireSwal.error({
              title: translate('img-resize', 'no-backtick-allowed__title', locale),
              text: translate('img-resize', 'no-backtick-allowed__text', locale),
            });
            input.value = null;
            return;
          }
        } else {
          if (preview)
            preview.src =
              '/assets/file_icons/video-27171972abad8c900fcd039956ad4816c8d6e4f15e3b9a4e023832356e9c40e2.png';
          this.filename = file.name;
          this.open = false;
          return;
        }

        const img = new Image();
        const reader = new FileReader();
        reader.readAsDataURL(file);

        // we wait for the reader to load the file
        reader.onload = () => {
          img.src = reader.result;
          // we wait for the image to read the reader
          img.onload = () => {
            // validate dimension
            if (img.width > MAX_IMAGE_DIMENSION || img.height > MAX_IMAGE_DIMENSION) {
              fireSwal.loading({ title: translate('img-resize', 'loading', locale) });

              reduce
                .toBlob(file, { max: MAX_IMAGE_DIMENSION })
                .then((blob) => {
                  // transfer reduced image back to input
                  let transfer = new DataTransfer();
                  const reducedImg = new File([blob], file.name);
                  transfer.items.add(reducedImg);
                  input.files = transfer.files;

                  fireSwal.success({ title: translate('img-resize', 'success', locale) });
                })
                .catch((e) => {
                  fireSwal.error({
                    title: translate('img-resize', 'error__title', locale),
                    text: translate('img-resize', 'error__text', locale),
                  });
                });
            } else {
              fireSwal.success({ title: translate('img-resize', 'size-ok', locale) });
            }
          };

          // if there is a preview image (e.g. carousel)
          if (preview) preview.src = reader.result;
          this.filename = file.name;
          this.open = false;
          this.image_url = null;
          this.showPlaceholder = false;
        };
      }
    },

    handleDrop(event) {
      // Ignore if dataTransfer doesn't contain exactly one item
      if (event.dataTransfer.files.length !== 1) return;

      this.$refs.input.files = event.dataTransfer.files;
      this.handleInput();
    },

    dropzone: {
      ['@drop.prevent'](event) {
        this.handleDrop(event);
      },
      ['@dragover.prevent']() {
        // Just here to prevent the file from being opened
      },
    },

    overlay: {
      ['x-show']() {
        return this.open;
      },
      ['x-transition:enter']() {
        return 'transition ease-out duration-300';
      },
      ['x-transition:enter-start']() {
        return 'opacity-0';
      },
      ['x-transition:enter-end']() {
        return 'opacity-100';
      },
      ['x-transition:leave']() {
        return 'ease-in duration-200';
      },
      ['x-transition:leave-start']() {
        return 'opacity-100';
      },
      ['x-transition:leave-end']() {
        return 'opacity-0';
      },
      ['@click']() {
        this.open = false;
      },
    },

    panel: {
      ['x-show']() {
        return this.open;
      },
      ['x-on:close']() {
        this.open = false;
      },
      ['x-on:click.outside']() {
        this.open = false;
      },
      ['@keyup.esc.prevent']() {
        this.open = false;
      },
      ['x-transition:enter']() {
        return 'transition ease-out duration-300';
      },
      ['x-transition:enter-start']() {
        return 'transform opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95';
      },
      ['x-transition:enter-end']() {
        return 'opacity-100 translate-y-0 sm:scale-100';
      },
      ['x-transition:leave']() {
        return 'ease-in duration-200';
      },
      ['x-transition:leave-start']() {
        return 'opacity-100 translate-y-0 sm:scale-100';
      },
      ['x-transition:leave-end']() {
        return 'opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95';
      },
    },
  };
}

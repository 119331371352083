export default function organizationSettings() {
  return {
    openAllSections: false,
    useTwoColumns: false,
    openGeneral: false,
    openEmail: false,
    openLanding: false,
    openLanguage: false,
    openSocialSignIn: false,
    openAnalytics: false,
    openFooter: false,
    openCustom: false,
    openStyling: false,

    init() {
      if (this.supports_html5_storage()) {
        if (localStorage.getItem('useTwoColumns')) {
          this.useTwoColumns = localStorage.getItem('useTwoColumns') === 'true';
        }
        if (localStorage.getItem('openAllSections')) {
          this.openAllSections = localStorage.getItem('openAllSections') === 'true';
        }
      }
      this.setColumnLayout();
      this.setSectionFold();
    },

    switchSectionFold() {
      this.openAllSections = !this.openAllSections;
      localStorage.setItem('openAllSections', this.openAllSections);

      this.setSectionFold();
    },

    switchColumnView() {
      this.useTwoColumns = !this.useTwoColumns;
      localStorage.setItem('useTwoColumns', this.useTwoColumns);

      this.setColumnLayout();
    },

    setSectionFold() {
      // these attributes only control the chevron icon to point up or down
      this.openGeneral = this.openAllSections;
      this.openEmail = this.openAllSections;
      this.openLanding = this.openAllSections;
      this.openLanguage = this.openAllSections;
      this.openSocialSignIn = this.openAllSections;
      this.openAnalytics = this.openAllSections;
      this.openFooter = this.openAllSections;
      this.openCustom = this.openAllSections;
      this.openStyling = this.openAllSections;

      // open or close all sections
      const cardIds = [
        'general',
        'email',
        'landing',
        'language',
        'socialSignIn',
        'analytics',
        'footer',
        'custom',
        'styling'
      ];

      cardIds.forEach((cardId) => {
        if (document.getElementById(cardId)) {
          document.getElementById(cardId).open = this.openAllSections;
        }
      });
    },

    setColumnLayout() {
      if (this.useTwoColumns) {
        document.getElementById('settingsWrapper').classList.add('xl:flex', 'xl:gap-4', 'xl:space-y-0');
      } else {
        document.getElementById('settingsWrapper').classList.remove('xl:flex', 'xl:gap-4', 'xl:space-y-0');
      }
    },

    supports_html5_storage() {
      try {
        return 'localStorage' in window && window['localStorage'] !== null;
      } catch (e) {
        return false;
      }
    },
  };
}

export default function clipboard(url) {
  return {
    url,
    showSuccess: false,
    showError: false,
  
    handleClick() {
      try {
        window.navigator.clipboard.writeText(this.url)
        this.showSuccess = true;
        setTimeout(() => this.showSuccess = false, 1500)
      } catch {
        this.showError = true;
        setTimeout(() => this.showError = false, 1500)
      }
    }
  }
}

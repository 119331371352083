import Sortable from 'sortablejs';

// A tiny wrapper around Sortable
// By default we use the root element of this component
// You can also set an element within this component with target
export default function sortableHomepage({ paramKey, target = null }) {
  return {
    sortable: null,
    paramKey: paramKey,

    init() {
      const selectedTarget = this.$el.querySelector(target);
      this.sortable = new Sortable(selectedTarget ?? this.$el, {
        handle: '[data-sortable="handle"]',
        dataIdAttr: 'data-sortable-id',
        onUpdate: () => this.update(),
      });
      
    },

    update() {
      const sortorder = this.sortable.toArray();
      sortorder.forEach((element, index) => {
        const selected = document.getElementById(`project_${element}_homepage_sortorder`);
        selected.value = index + 1;
      });
    },
    
  };
}

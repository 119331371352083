// Helpers functions are used inside .js.haml files

export function replaceContent(query, content) {
  const el = document.querySelector(query);
  el.outerHTML = content;
}

export function closeModal(id) {
  const modal = document.querySelector(id);
  const closeEvent = new Event("close");
  modal.dispatchEvent(closeEvent);
}

// Attach all function to window
window.bpart = {};
window.bpart.closeModal = closeModal;
window.bpart.replaceContent = replaceContent;

export default function autoFlag(name, url) {
  return {
    name,
    url,

    init() {
      this.$el.addEventListener("change", () => this.handleChange());
    },

    handleChange() {
      const body = { flags: {} };
      body.flags[this.name] = this.$root.checked;

      const token = document.querySelector('meta[name=csrf-token]')?.getAttribute('content');
      const request = new Request(this.url, {
        method: 'PATCH',
        headers: {
          'content-type': 'application/json',
          'X-CSRF-Token': token,
        },
        body: JSON.stringify(body),
      })
      fetch(request)
    }
  }
}

import { scrollIntoViewIfNeeded } from '~/scripts/scroll';

export default function autocomplete({
  activeClass = 'autocomplete__option--active',
  selectedClass = 'autocomplete__option--selected',
} = {}) {
  return {
    expanded: false,
    query: '',
    options: [],
    groups: [],
    activeIndex: -1,
    selectedIndex: -1,
    activeClasses: Array.isArray(activeClass) ? activeClass : [activeClass],
    selectedClasses: Array.isArray(selectedClass) ? selectedClass : [selectedClass],

    init() {
      const value = this.$refs.field.value;

      // Style element based on selectedIndex and activeIndex
      // We need to setup these watchers before initializing the options
      this.$watch('activeIndex', (index, oldIndex) => {
        this.options[oldIndex]?.classList.remove(...this.activeClasses);
        this.options[index]?.classList.add(...this.activeClasses);
      });
      this.$watch('selectedIndex', (index, oldIndex) => {
        this.options[oldIndex]?.classList.remove(...this.selectedClasses);
        this.options[index]?.classList.add(...this.selectedClasses);
        let newValue = this.options[index].getAttribute('data-autocomplete-value');
        if (newValue === 'new') {
          let newOption = document.createElement('option');
          newValue = this.query;
          newOption.value = newValue;
          this.$refs.field.appendChild(newOption);
        }
        this.$refs.field.value = newValue;
      });

      // Find all options and add event listeners
      this.options = this.$el.querySelectorAll('[role="option"]');
      this.groups = this.$el.querySelectorAll('[role="optgroup"]');
      this.options.forEach((option, index) => {
        option.addEventListener('mouseover', () => (this.activeIndex = index));
        option.addEventListener('mouseout', () => (this.activeIndex = -1));
        option.addEventListener('click', () => this.setSelectedOption());
        if (option.getAttribute('data-autocomplete-value') === value) this.selectedIndex = index;
      });

      // Reset the activeElement when collapsing
      this.$watch('expanded', () => !this.expanded && (this.activeIndex = -1));

      // Make sure options are in view when opened
      this.$watch('expanded', () => {
        if (this.expanded) {
          this.$nextTick(() => scrollIntoViewIfNeeded(this.$root.querySelector("[x-bind='target']")));
        }
      });

      // Filter options based on
      this.$watch('query', () => this.filterOptions());

      // Hide the fallback field
      this.$refs.field.hidden = true;
    },

    setSelectedOption() {
      if (this.activeIndex !== -1) this.selectedIndex = this.activeIndex;
      this.expanded = !this.expanded;
    },

    filterOptions() {
      this.options.forEach(
        (o) =>
        (o.hidden =
          !(this.query.length === 0 || o.getAttribute('data-autocomplete-value') === 'new') &&
          o.textContent.trim().toLowerCase().indexOf(this.query.toLowerCase()) === -1)
      );
      this.groups.forEach((g) => (g.hidden = g.querySelectorAll('[role="option"]:not([hidden])').length === 0));
    },

    findNextActive(dir) {
      const up = dir === 'up';
      if (!this.expanded) this.expanded = true;
      let newIndex = this.activeIndex;
      do {
        newIndex += up ? -1 : 1;
        // Break out of loop if there is no next visible option
        if ((up && newIndex === -1) || newIndex === this.options.length) return;
      } while (this.options[newIndex].hidden || this.options.disabled);
      this.activeIndex = newIndex;
    },

    get displayValue() {
      if (this.expanded) return this.query;

      const selectedOption = this.options[this.selectedIndex];
      return selectedOption ? selectedOption.textContent : '';
    },

    trigger: {
      ['@click']() {
        this.expanded = true;
      },
      ['@keyup.esc.prevent.stop']() {
        this.expanded = false;
      },
      ['@keyup.enter.prevent.stop']() {
        this.setSelectedOption();
      },
      ['@keyup.arrow-up.prevent']() {
        this.findNextActive('up');
      },
      ['@keyup.arrow-down.prevent']() {
        this.findNextActive('down');
      },
      ['@input']($event) {
        this.expanded = true;
        this.query = $event.target.value;
      },
    },

    target: {
      ['x-show']() {
        return this.expanded;
      },
      ['x-transition:enter']() {
        return 'transition ease-out duration-100';
      },
      ['x-transition:enter-start']() {
        return 'transform opacity-0 scale-95';
      },
      ['x-transition:enter-end']() {
        return 'transform opacity-100 scale-100';
      },
      ['x-transition:leave']() {
        return 'transition ease-in duration-75';
      },
      ['x-transition:leave-start']() {
        return 'transform opacity-100 scale-100';
      },
      ['x-transition:leave-end']() {
        return 'transform opacity-0 scale-95';
      },
    },
  };
}

import 'core-js/stable';
import 'regenerator-runtime/runtime';
import '@hotwired/turbo-rails';

// Import and start extra dependencies
import '../scripts';
import { scrollToInvalid } from '../scripts/scroll';

// Setup Alpine and components
import Alpine from 'alpinejs';
import autocomplete from '../alpine/auto-complete';
import autoFlag from '../alpine/auto-flag';
import autoUpdate from '../alpine/auto-update';
import beforeUnload from '../alpine/before-unload';
import clipboard from '../alpine/clipboard';
import combobox from '../alpine/combobox';
import dropdown from '../alpine/dropdown';
import fileUpload from '../alpine/file-upload';
import formBuilder from '../alpine/form-builder';
import googleMapsAdmin from '../alpine/google-maps-admin';
import modal from '../alpine/modal';
import moderationFilters from '../alpine/moderation-filters';
import moderationMailTemplates from '../alpine/moderation-mail-templates';
import organizationSettings from '../alpine/organization-settings';
import projectPath from '../alpine/project-path';
import projectSettings from '../alpine/project-settings';
import sidebar from '../alpine/sidebar';
import sortableList from '../alpine/sortable-list';
import sortableHomepage from '../alpine/sortable-homepage';
import settingsWizard from '../alpine/settings-wizard';

window.Alpine = Alpine;
Alpine.data('autocomplete', autocomplete);
Alpine.data('autoFlag', autoFlag);
Alpine.data('autoUpdate', autoUpdate);
Alpine.data('beforeUnload', beforeUnload);
Alpine.data('clipboard', clipboard);
Alpine.data('combobox', combobox);
Alpine.data('dropdown', dropdown);
Alpine.data('fileUpload', fileUpload);
Alpine.data('formBuilder', formBuilder);
Alpine.data('googleMapsAdmin', googleMapsAdmin);
Alpine.data('modal', modal);
Alpine.data('moderationFilters', moderationFilters);
Alpine.data('moderationMailTemplates', moderationMailTemplates);
Alpine.data('organizationSettings', organizationSettings);
Alpine.data('projectPath', projectPath);
Alpine.data('projectSettings', projectSettings);
Alpine.data('sidebar', sidebar);
Alpine.data('sortableList', sortableList);
Alpine.data('sortableHomepage', sortableHomepage);
Alpine.data('settingsWizard', settingsWizard);

document.addEventListener('DOMContentLoaded', () => {
  Alpine.start();
  scrollToInvalid();
});

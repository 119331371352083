import { translate } from '~/scripts/locales/translations';

export default function beforeUnload() {
  return {
    unsavedChanges: false,

    init() {
      this.$el.addEventListener('tinymcechange', () => this.setBeforeUnload());
      this.beforeUnloadHandler = this.beforeUnloadHandler.bind(this);
    },

    beforeUnloadHandler(event) {
      if (!this.unsavedChanges) return;

      if (event.type == 'beforeunload') {
        event.preventDefault();
      } else {
        const discardUnsavedChangesMsg = translate('before-unload', 'warning-message', document.documentElement.lang);

        if (window.confirm(discardUnsavedChangesMsg)) {
          this.unsavedChanges = false;
        } else {
          event.preventDefault();
        }
      }
    },

    // 'closeModal' is a custom event fired by the modal component
    setBeforeUnload() {
      if (this.unsavedChanges) return;
      this.unsavedChanges = true;
      window.addEventListener('beforeunload', this.beforeUnloadHandler);
      window.addEventListener('turbo:before-visit', this.beforeUnloadHandler);
      window.addEventListener('closeModal', this.beforeUnloadHandler);
    },

    unsetBeforeUnload() {
      this.unsavedChanges = false;
      window.removeEventListener('beforeunload', this.beforeUnloadHandler);
      window.removeEventListener('turbo:before-visit', this.beforeUnloadHandler);
      window.removeEventListener('closeModal', this.beforeUnloadHandler);
    },

    form: {
      ['x-on:input']() {
        this.setBeforeUnload();
      },
      ['x-on:submit']() {
        this.unsetBeforeUnload();
      },
    },

    googleMaps: {
      ['x-on:click']() {
        this.setBeforeUnload();
      },
    },
  };
}

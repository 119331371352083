  export default function dropdown({ activeClass = 'dropdown__item--active' } = {}) {
  return {
    expanded: false,
    activeIndex: -1,
    activeClasses: Array.isArray(activeClass) ? activeClass : [activeClass],

    init() {
      this.items = this.$el.querySelectorAll('[role="menuitem"]');
      this.items.forEach((item, index) => {
        item.addEventListener('mouseover', () => (this.activeIndex = index));
        item.addEventListener('mouseout', () => (this.activeIndex = -1));
      });
      this.$watch('expanded', () => !this.expanded && (this.activeIndex = -1));
      this.$watch('activeIndex', (index, oldIndex) => {
        this.items[oldIndex]?.classList.remove(...this.activeClasses);
        this.items[index]?.classList.add(...this.activeClasses);
      });
    },

    searchMatch(searchTerm, word) {
      if (searchTerm.replace(/\s/g, '') == '') return true;
      return word.toLowerCase().includes(searchTerm.toLowerCase());
    },

    trigger: {
      ['@click']() {
        this.expanded = !this.expanded;
        if (this.expanded) this.$refs.input?.focus();
        if (!this.expanded) this.$refs.input?.blur();
      },
      ['@keyup.arrow-up.prevent']() {
        if (!this.expanded) this.expanded = true;
        this.activeIndex !== 0 &&
          (this.activeIndex = this.activeIndex === -1 ? this.items.length - 1 : this.activeIndex - 1);
      },
      ['@keyup.arrow-down.prevent']() {
        if (!this.expanded) this.expanded = true;
        this.activeIndex !== this.items.length - 1 && this.activeIndex++;
      },
      ['@focusout']() {
        // We need to make sure a click inside the dropdown is followed before we collapse the dropdown
        setTimeout(() => (this.expanded = false), 500);
      },
    },

    target: {
      ['x-show']() {
        return this.expanded;
      },
      ['@keyup.arrow-up.prevent']() {
        this.activeIndex !== 0 && (this.activeIndex === -1 ? this.items.length - 1 : this.activeIndex - 1);
      },
      ['@keyup.arrow-down.prevent']() {
        this.activeIndex !== this.items.length - 1 && this.activeIndex++;
      },
      ['x-transition:enter']() {
        return 'transition ease-out duration-100';
      },
      ['x-transition:enter-start']() {
        return 'transform opacity-0 scale-95';
      },
      ['x-transition:enter-end']() {
        return 'transform opacity-100 scale-100';
      },
      ['x-transition:leave']() {
        return 'transition ease-in duration-75';
      },
      ['x-transition:leave-start']() {
        return 'transform opacity-100 scale-100';
      },
      ['x-transition:leave-end']() {
        return 'transform opacity-0 scale-95';
      },
    },
  };
}

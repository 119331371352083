// NOTE(Robbe): This file shares a lot of similarities with the autocomplete component
// It might be a good idea to have them share the underlying logic instead of duplicating the code

import { scrollIntoViewIfNeeded } from '~/scripts/scroll';

export default function combobox({
  activeClass = 'combobox__option--active',
  selectedClass = 'combobox__option--selected',
} = {}) {
  return {
    expanded: false,
    query: '',
    options: [],
    groups: [],
    activeIndex: -1,
    selectedIndexes: [],
    selectedValues: [],
    activeClasses: Array.isArray(activeClass) ? activeClass : [activeClass],
    selectedClasses: Array.isArray(selectedClass) ? selectedClass : [selectedClass],

    init() {
      // Fill selectedValues from fallback select
      [...this.$refs.field.selectedOptions].forEach((opt) => this.selectedValues.push(opt.value));

      // Style element based on selectedIndex and activeIndex
      // We need to setup these watchers before initializing the options
      this.$watch('activeIndex', (index, oldIndex) => {
        this.options[oldIndex]?.classList.remove(...this.activeClasses);
        this.options[index]?.classList.add(...this.activeClasses);
      });
      this.$watch('selectedIndexes', (indexes, oldIndexes) => {
        oldIndexes.forEach((oldIndex) => this.options[oldIndex]?.classList.remove(...this.selectedClasses));
        indexes.forEach((index) => this.options[index]?.classList.add(...this.selectedClasses));
      });

      // Find all options and add event listeners
      this.options = this.$el.querySelectorAll('[role="option"]');
      this.groups = this.$el.querySelectorAll('[role="optgroup"]');
      this.options.forEach((option, index) => {
        option.addEventListener('mouseover', () => (this.activeIndex = index));
        option.addEventListener('mouseout', () => (this.activeIndex = -1));
        option.addEventListener('click', () => this.toggleSelectedOption());
        if (this.selectedValues.includes(option.getAttribute('data-combobox-value'))) this.selectedIndexes.push(index);
      });

      // Reset the activeElement when collapsing
      this.$watch('expanded', () => !this.expanded && (this.activeIndex = -1));

      // Make sure options are in view after opening
      this.$watch('expanded', () => {
        if (this.expanded) {
          this.$nextTick(() => scrollIntoViewIfNeeded(this.$root.querySelector("[x-bind='target']")));
        }
      });

      // Filter options based on
      this.$watch('query', () => this.filterOptions());

      // Hide the fallback field
      this.$refs.field.hidden = true;
    },

    toggleSelectedOption() {
      if (this.activeIndex !== -1)
        this.selectedIndexes.includes(this.activeIndex) ? this.removeSelectedOption() : this.addSelectedOption();

      this.expanded = !this.expanded;
    },

    addSelectedOption() {
      let newValue = this.options[this.activeIndex].getAttribute('data-combobox-value');

      if (newValue === 'new') {
        // if new value, create an option in fallback and mark it as selected
        let newOption = document.createElement('option');
        newValue = this.query;
        newOption.value = newValue;
        newOption.setAttribute('selected', 'selected');
        this.$refs.field.appendChild(newOption);
      } else {
        // if existing value, find the option and mark it as selected
        this.selectedIndexes.push(this.activeIndex);
        this.$refs.field.querySelector(`[value="${newValue}"]`).setAttribute('selected', 'selected');
      }
      // Add value to list
      this.selectedValues.push(newValue);

      // Reset query
      this.query = '';
      this.$refs.input.value = '';
    },

    removeSelectedOption(value = null) {
      // If we pass a value, get the index in the options list of that value
      if (value) this.activeIndex = [...this.options].findIndex((o) => o.value === value);

      // Remove from list of selectedIndexes
      const index = this.selectedIndexes.findIndex((opt) => opt === this.activeIndex);
      if (index !== -1) this.selectedIndexes.splice(index, 1);

      // Find value of option if not present
      if (!value) value = this.options[this.activeIndex].getAttribute('data-combobox-value');

      // Deselect option
      this.$refs.field.querySelector(`[value="${value}"]`).removeAttribute('selected');

      // Remove option from list of selectedValues
      const valueIndex = this.selectedValues.findIndex((v) => v === value);
      if (valueIndex !== -1) this.selectedValues.splice(valueIndex, 1);
    },

    filterOptions() {
      this.options.forEach(
        (o) =>
          (o.hidden =
            !(this.query.length === 0 || o.getAttribute('data-combobox-value') === 'new') &&
            o.textContent.trim().toLowerCase().indexOf(this.query.toLowerCase()) === -1)
      );
      this.groups.forEach((g) => (g.hidden = g.querySelectorAll('[role="option"]:not([hidden])').length === 0));
    },

    findNextActive(dir) {
      const up = dir === 'up';
      if (!this.expanded) this.expanded = true;
      let newIndex = this.activeIndex;
      do {
        newIndex += up ? -1 : 1;
        // Break out of loop if there is no next visible option
        if ((up && newIndex === -1) || newIndex === this.options.length) return;
      } while (this.options[newIndex].hidden || this.options.disabled);
      this.activeIndex = newIndex;
    },

    trigger: {
      ['@click']() {
        this.expanded = true;
      },
      ['@keyup.esc.prevent.stop']() {
        this.expanded = false;
      },
      ['@keydown.enter.prevent.stop']() {
        this.toggleSelectedOption();
      },
      ['@keyup.arrow-up.prevent']() {
        this.findNextActive('up');
      },
      ['@keyup.arrow-down.prevent']() {
        this.findNextActive('down');
      },
      ['@input']($event) {
        this.expanded = true;
        this.query = $event.target.value;
      },
    },

    target: {
      ['x-show']() {
        return this.expanded;
      },
      ['x-transition:enter']() {
        return 'transition ease-out duration-100';
      },
      ['x-transition:enter-start']() {
        return 'transform opacity-0 scale-95';
      },
      ['x-transition:enter-end']() {
        return 'transform opacity-100 scale-100';
      },
      ['x-transition:leave']() {
        return 'transition ease-in duration-75';
      },
      ['x-transition:leave-start']() {
        return 'transform opacity-100 scale-100';
      },
      ['x-transition:leave-end']() {
        return 'transform opacity-0 scale-95';
      },
    },
  };
}

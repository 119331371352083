export default function projectSettings() {
  return {
    openAllSections: false,
    useTwoColumns: false,
    openGeneral: false,
    openSocial: false,
    openIdeation: false,
    openHomepage: false,
    openGoogle: false,
    openLanding: false,
    openAuthentication: false,
    openEmails: false,
    openAnalytics: false,
    openLanguage: false,
    openTemplate: false,
    openFooter: false,
    openCcmServices: false,
    openVoting: false,
    openUrl: false,

    init() {
      if (this.supports_html5_storage()) {
        if (localStorage.getItem('useTwoColumns')) {
          this.useTwoColumns = localStorage.getItem('useTwoColumns') === 'true';
        }
        if (localStorage.getItem('openAllSections')) {
          this.openAllSections = localStorage.getItem('openAllSections') === 'true';
        }
      }
      this.setColumnLayout();
      this.setSectionFold();
    },

    switchSectionFold() {
      this.openAllSections = !this.openAllSections;
      localStorage.setItem('openAllSections', this.openAllSections);

      this.setSectionFold();
    },

    switchColumnView() {
      this.useTwoColumns = !this.useTwoColumns;
      localStorage.setItem('useTwoColumns', this.useTwoColumns);

      this.setColumnLayout();
    },

    setSectionFold() {
      // these attributes only control the chevron icon to point up or down
      this.openGeneral = this.openAllSections;
      this.openSocial = this.openAllSections;
      this.openIdeation = this.openAllSections;
      this.openHomepage = this.openAllSections;
      this.openGoogle = this.openAllSections;
      this.openLanding = this.openAllSections;
      this.openAuthentication = this.openAllSections;
      this.openEmails = this.openAllSections;
      this.openAnalytics = this.openAllSections;
      this.openLanguage = this.openAllSections;
      this.openTemplate = this.openAllSections;
      this.openFooter = this.openAllSections;
      this.openCcmServices = this.openAllSections;
      this.openVoting = this.openAllSections;
      this.openUrl = this.openAllSections;

      // open or close all sections
      const cardIds = [
        'general',
        'social',
        'ideation',
        'homepage',
        'google',
        'landing',
        'authentication',
        'emails',
        'analytics',
        'language',
        'template',
        'footer',
        'services',
        'initial',
        'voting',
        'url',
      ];

      cardIds.forEach((cardId) => {
        if (document.getElementById(cardId)) {
          document.getElementById(cardId).open = this.openAllSections;
        }
      });
    },

    setColumnLayout() {
      if (this.useTwoColumns) {
        document.getElementById('settingsWrapper').classList.add('xl:flex', 'xl:gap-4', 'xl:space-y-0');
      } else {
        document.getElementById('settingsWrapper').classList.remove('xl:flex', 'xl:gap-4', 'xl:space-y-0');
      }
    },

    supports_html5_storage() {
      try {
        return 'localStorage' in window && window['localStorage'] !== null;
      } catch (e) {
        return false;
      }
    },
  };
}

import Sortable from 'sortablejs';

// A tiny wrapper around Sortable
// By default we use the root element of this component
// You can also set an element within this component with target
export default function sortableList({ paramKey, target = null }) {
  return {
    sortable: null,
    paramKey: paramKey,

    init() {
      const selectedTarget = this.$el.querySelector(target);
      this.sortable = new Sortable(selectedTarget ?? this.$el, {
        handle: '[data-sortable="handle"]',
        dataIdAttr: 'data-sortable-id',
        onUpdate: () => this.update(),
      });
      
    },

    update() {
      const body = {}
      body[this.paramKey] = this.sortable.toArray();
      const token = document.querySelector('meta[name=csrf-token]')?.getAttribute('content');
      const request = new Request(this.$root.getAttribute('data-sortable-update-url'), {
        method: 'POST',
        headers: {
          'content-type': 'application/json',
          'X-CSRF-Token': token,
        },
        body: JSON.stringify(body),
      });
      fetch(request);
    },
    
  };
}
